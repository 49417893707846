import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from '../../../interfaces/requests';
import { Locale } from '../../../locales';

export function retrieveTranslationMessages(locale: Locale, translationKeys?: string[]) {
  return axios.get<RequestResult<Record<string, string>>>(
    `${getConfig().publicRuntimeConfig.API_URI}/v1/${locale}/translations`,
    {
      params: {
        translationKeys
      }
    }
  );
}

export function updateTranslation(accessToken: string, locale: string, translationKey?: string, newValue?: string) {
  return axios.post(
    `${getConfig().publicRuntimeConfig.API_URI}/v1/${locale}/translations`,
    {
      locale,
      translationKey,
      newValue
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {}
    }
  );
}
