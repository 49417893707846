import '@formatjs/intl-pluralrules/polyfill-locales';
import momentDe from 'moment/locale/de-ch';
import momentEn from 'moment/locale/en-gb';
import momentFr from 'moment/locale/fr-ch';
import momentIt from 'moment/locale/it-ch';
import { NextPageContext } from 'next';
import getConfig from 'next/config';
import { useIntl } from 'react-intl';
import { retrieveTranslationMessages } from '../redux/modules/app/operations';
import { AppState } from '../redux/reducers';

export type Locale = 'en' | 'de' | 'fr' | 'it';

export const momentLocales: Record<Locale, { code: string; locale: any }> = {
  en: { code: 'en-gb', locale: momentEn },
  de: { code: 'de-ch', locale: momentDe },
  fr: { code: 'fr-ch', locale: momentFr },
  it: { code: 'it-ch', locale: momentIt }
};

export const availableLocales: Locale[] = ['de', 'fr', 'it', 'en'];

export const defaultLocale = (): Locale => getConfig().publicRuntimeConfig.defaultLocale;

const cachedTranslations: Record<
  Locale,
  { __all: Record<string, string>; [cacheKey: string]: Record<string, string> }
> = { de: { __all: {} }, fr: { __all: {} }, it: { __all: {} }, en: { __all: {} } };

export const clearCachedTranslations = () => {
  Object.getOwnPropertyNames(cachedTranslations).forEach(function (prop) {
    delete cachedTranslations[prop];
  });

  cachedTranslations.de = { __all: {} };
  cachedTranslations.fr = { __all: {} };
  cachedTranslations.it = { __all: {} };
  cachedTranslations.en = { __all: {} };
};

export const getTranslations = async (locale: Locale = defaultLocale(), paths: string[] = []) => {
  const cacheKey = paths.length === 0 ? '__all' : paths.join('');

  const cache = cachedTranslations[locale][cacheKey];
  if (cache && Object.keys(cache).length > 0) {
    return cache;
  }

  let messages: Record<string, string> = {};
  try {
    let response = await retrieveTranslationMessages(locale, paths);
    if (!response.data.result) {
      console.warn(
        `Missing locale data for locale: "${locale}". Using default locale: "${defaultLocale()}" as fallback.`
      );
      locale = defaultLocale();
      response = await retrieveTranslationMessages(locale);
    }

    messages = response.data.result ? response.data.result : messages;
    cachedTranslations[locale][cacheKey] = messages;
  } catch {
    console.error(`Could not retrieve translations for ${locale}`);
  }

  return messages;
};

export const useLocale = (): Locale => {
  const intl = useIntl();

  if (intl) {
    const locale = intl.formatMessage({ id: 'locale' });
    if (locale !== 'locale' && availableLocales.includes(locale as any)) {
      return locale as Locale;
    }
  }

  return getConfig().publicRuntimeConfig.defaultLocale;
};

export const getLocale = (ctx: NextPageContext): Locale => {
  const { language } = (ctx.store.getState() as AppState).appReducer;

  if (language) {
    return language as Locale;
  }

  if (typeof window !== 'undefined' && window.navigator.languages.length > 0) {
    const twoLetterCodes = window.navigator.languages.filter(x => x.length === 2);
    if (twoLetterCodes && twoLetterCodes.length > 0) {
      return twoLetterCodes[0] as Locale;
    }
  }

  return getConfig().publicRuntimeConfig.defaultLocale;
};

const idNotationMap = {
  B: 'notation.capitalizedB',
  sB: 'notation.singularB',
  M: 'notation.capitalizedM',
  sM: 'notation.singularM',
  K: 'notation.singularK',
  sK: 'notation.singularK',
  T: 'notation.capitalizedT',
  sT: 'notation.singularT'
};

export const getIdFromNotation = (notation: 'K' | 'sK' | 'M' | 'sM' | 'B' | 'sB' | 'T' | 'sT'): string =>
  idNotationMap[notation];
